import {Button, ButtonProps} from '#app/components/ui/button';
import ArrowButtonMenuIcon from '#app/assets/icons/arrow-button-menu.svg?react'
import AccentArrowButtonMenuIcon from '#app/assets/icons/arrow-button-menu-accent.svg?react'
import PlusButtonMenuIcon from '#app/assets/icons/plus-button-menu.svg?react'
import CheckmarkButtonMenuIcon from '#app/assets/icons/checkmark-button-menu.svg?react'
import ShoppingCartButtonMenuIcon from '#app/assets/icons/shopping-cart-button-menu-accent.svg?react'
import { cn } from '#app/utils/misc';

type MenuButtonProps = ButtonProps & {
    icon: 'arrow-forward' | 'arrow-back' | 'arrow-back:outline' | 'plus' | 'checkmark' | 'shopping-cart',
    variant?: 'menu' | 'menu:outlined';
}

const Icons = {
    'arrow-forward': () => <ArrowButtonMenuIcon className="-mr-2"  />,
    'arrow-back': () => <ArrowButtonMenuIcon className="-mr-2 rotate-180"  />,
    'arrow-back:outline': () => <AccentArrowButtonMenuIcon className="-mr-2 rotate-180"  />,
    'plus': () => <PlusButtonMenuIcon className="-mr-2"  />,
    'checkmark': () => <CheckmarkButtonMenuIcon className="-mr-2"  />,
    'shopping-cart': () => <ShoppingCartButtonMenuIcon className="-mr-2"  />

}

export const MenuButton = ({children,icon, variant = 'menu',className, ...props}: MenuButtonProps) => {
   return <Button className={cn("pl-2 pr-4 py-1.5 justify-between", className)} variant={variant} LeftIcon={Icons[icon]} {...props} >
    <span className='flex-1'>
    {children}
    </span>
   </Button>
}